<!--登录页-->
<template>
  <div class="login-enter-view">
    <div class="section-center-view">
      <div class="identifying">
        <img class="logo" :src="school_info.logo || require('@/assets/images/logo.png')" />
        <div class="schoolName">{{school_info.systemName}}</div>
      </div>
      <div class="content">
        <div class="left-exhibition-img-warp" :style="`background-image:url(${school_info.schoolImage || require('@/assets/images/school-picture.png')})`" />
        <div class="right-login-verification">
          <el-form label-position="top" :model="loginForm" :rules="rules" ref="loginForm">
            <el-form-item label="手机号" prop="mobile">
              <el-input v-model="loginForm.mobile" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
              <el-row>
                <el-col :span="14">
                  <el-input v-model="loginForm.code" placeholder="请输入验证码"></el-input>
                </el-col>
                <el-col :span="9" :offset="1">
                  <el-button type="primary" style="width:100%" :disabled="ageing !== 0" @click="sendCodeHandle">{{ ageing === 0 ? '获取验证码' : ageing + 's'}}</el-button>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" style="width: 100%; margin-top: 18px" @click="submitLoginHandle">
                登录
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="footer-school-notice">
        <div class="strip">
          <span class="text" @click="$router.push(`/${$route.params.id}/home/procalamation`)">招生公告</span>
        </div>
        <div class="strip">
          <span class="text" @click="$router.push(`/${$route.params.id}/home/guide`)">报名指南</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { sendPhoneCode, loginAsk } from "@/api/login"
import { SCHOOL_INFO, RECRUIT_USER_ID } from "@/store/mutation-types"
export default {
  name: "LoginEnter",
  data() {
    return {
      loginForm: {
        mobile: "",
        code: ""
      },
      ageing: 0,
      rules: {
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789][0-9]{9}$/,
            message: "请输入有效手机号",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
          {
            pattern: /^[0-9]{6}$/,
            message: "请输入有效验证码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(['school_info'])
  },
  methods: {
    sendCodeHandle() {
      if (this.loginForm.mobile.length !== 11) return
      this.ageing = 30;
      let reckon = setInterval(() => {
        this.ageing = this.ageing - 1;
        if (this.ageing === 0) clearInterval(reckon);
      }, 1000);
      sendPhoneCode({ mobile: this.loginForm.mobile }).then(res => {
        if (res.code === 200) {
          this.$message.success('验证码已发送，请注意手机短信查收~')
        } else {
          this.$message.error('验证码发送失败，请稍后在试~')
        }
      })
    },
    submitLoginHandle() {
      this.$refs.loginForm.validate((flage, obj) => {
        let params = {
          recruitUserId: this.$route.query?.recruitUserId || this.$ls.get(RECRUIT_USER_ID),
          schoolId: this.$ls.get(SCHOOL_INFO).id,
          ...this.loginForm
        }

        if (flage) {
          loginAsk(params).then(res => {
            if (res.code === 200) {
              this.$store.dispatch('app/Login', res.data);
              this.$router.push(`/${this.$route.params.id}/home`)
            } else {
              this.$message.error('登录验证失败，请稍后再试~')
            }
          })
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.login-enter-view {
  width: 100vw;
  height: 100vh;
  background: url("@/assets/images/login-bg.png") 0 0 no-repeat;
  background-size: 100% 100%;

  .section-center-view {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1054px;
    height: 530px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(75, 66, 66, 0.1);
    box-sizing: border-box;
    padding: 0 54px;

    .identifying {
      display: flex;
      align-items: center;
      margin: 50px 0;

      .logo {
        width: 50px;
      }

      .schoolName {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        color: var(--theme-weaken-color-2);
        margin-left: 20px;
      }
    }

    .content {
      display: flex;
      height: 270px;
      justify-content: space-between;

      .left-exhibition-img-warp {
        width: 600px;
        height: 270px;
        border-radius: 4px;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .right-login-verification {
        width: 300px;

        /deep/.el-form-item__label {
          padding: 0;
          color: #323233;
        }
      }
    }

    .footer-school-notice {
      width: 600px;
      margin-top: 45px;
      display: flex;

      .strip {
        position: relative;
        font-size: 14px;
        box-sizing: border-box;
        padding-left: 10px;
        margin-right: 50px;

        .text {
          cursor: pointer;
          color: $fontColor;
        }

        .text:hover {
          color: var(--theme-weaken-color-2);
          text-decoration: underline;
        }
      }

      .strip:before {
        content: "";
        width: 4px;
        height: 4px;
        background-color: var(--theme-weaken-color-2);
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
</style>
