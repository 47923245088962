/**
 * 看实际业务场景需要
 */
/**防止重复点击重复弹出message弹框 */
import { Message } from "element-ui";

let messageInstance = null;
const resetMessage = (options) => {
  if (messageInstance) {
    messageInstance.close();
  }
  messageInstance = Message(options);
};
/**设置message弹框的格式 */
["error", "success", "info", "warning"].forEach((item) => {
  resetMessage[item] = (msg) => {
    return Message({
      duration: 3000,
      type: item,
      message: msg,
    });
  };
});
export const message = resetMessage;
