// 本地环境配置
module.exports = {
  title: " - 开发环境",
  baseApi: "https://csdoc.aiznclass.com", // 本地api请求地址,注意：如果你使用了代理，请设置成'/'
  port: 8080,
  mockFlag:true,
  options : {
    namespace: 'vuejs__', // key键前缀
    name: 'ls', // 命名Vue变量.[ls]或this.[$ls],
    storage: 'local', // 存储名称: session, local, memory
  }
};
