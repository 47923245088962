module.exports = {
  title: " - 测试环境",
  baseApi: "https://csdoc.aiznclass.com", // 测试api请求地址
  port: 8080,
  mockFlag: false,
  options: {
    namespace: 'vuejs__', // key键前缀
    name: 'ls', // 命名Vue变量.[ls]或this.[$ls],
    storage: 'local', // 存储名称: session, local, memory
  }
};
