import Vue from "vue";
import router from "./router";
import store from "./store";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import { getPageTitle } from "@/utils";
import { Message } from "element-ui";
import { getSchoolInfo } from "@/api/init"
import { SCHOOL_IDENTITY, ACCESS_TOKEN, SCHOOL_INFO, RECRUIT_USER_ID } from "@/store/mutation-types"



NProgress.configure({ showSpinner: false }); // NProgress Configuration

let initState = false; //初始化状态
const whiteList = ['login', 'procalamation', 'guide', '404'] // 页面路由白名单

//前置守卫（没有网络白名单限制
router.beforeEach(async (to, from, next) => {
    NProgress.start(); // start progress bar

    // 初始化学校信息 只执行一次
    if (!initState) {
        initState = true;

        let params = {
            link: to.params.id
        }

        const res = await getSchoolInfo(params)
        if (res.code === 200 && res.data) {
            Vue.ls.set(SCHOOL_IDENTITY, res.data[0].id);
            Vue.ls.set(SCHOOL_INFO, res.data[0]);
            to.query?.recruitUserId && Vue.ls.set(RECRUIT_USER_ID, to.query.recruitUserId);

            if (res.data[0]) {
                // 更换网页logo
                let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                link.type = 'image/x-icon';
                link.rel = 'shortcut icon';
                link.href = res.data[0].logo;
                document.getElementsByTagName('head')[0].appendChild(link);
            }

            store.dispatch('app/initSchool', Vue.ls.get(SCHOOL_INFO) || {})
        } else {
            next({ path: '/404', query: { text: '很抱歉，此链接为无效地址，请您及时联系工作人员查明原因！' } });
        }
    }

    document.title = getPageTitle(to.meta.title); //根据路由跳转显示标签标题

    //判断是否是去登录页面，是直接过
    if (to.name === 'login') {
        next()
        NProgress.done()
    } else {
        //不是去登录页面，判断用户是否登录过
        if (Vue.ls?.get(ACCESS_TOKEN)) {
            //登录了，判断是否有用户详细信息（如果有），比如角色权限，直接过，
            // const hasRoles = store.getters.userRoles && store.getters.userRoles.length > 0
            // if (hasRoles) {
            //     next()
            // } else {
            //     //没有用户详细信息，代表用户刷新了，或者其他行为，重新获取一次用户信息，
            //     // 并根据该用户的角色权限，来获取用户权限范围内能看到的界面
            //     try {
            //         //获取当前角色
            //         const {roles} = await store.dispatch('app/getInfo')
            //         //根据权限查询动态路由
            //         const accessRoutes = await store.dispatch('permission/generateRoutes', roles)
            //         //添加动态路由
            //         for (let i = 0; i < accessRoutes.length; i++) {
            //             const element = accessRoutes[i]
            //             router.addRoute(element)
            //         }

            //         const redirect = from.query.redirect || to.path
            //         if (to.path === redirect) {
            //             // 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
            //             next({...to, replace: true})
            //         } else {
            //             // 跳转到目的路由
            //             next({path: redirect})
            //         }
            //     } catch (error) {
            //         console.log(error)
            //         next({path: '/login', query: {redirect: to.fullPath}})
            //         NProgress.done()
            //     }
            // }
            next();
        } else {
            if (whiteList.indexOf(to.name) !== -1) {
                next()
            } else {
                next({ path: `/${from.params.id || to.params.id}/login` })
                NProgress.done()
                Message.error('请您登录后再来吧~')
            }
        }
    }
});


//后置守卫
router.afterEach(() => {
    NProgress.done(); // finish progress bar
});
